<template>
  <div
    class="ui-list"
    :class="{'--selectable': selectable}"
  >
    <slot></slot>

    <template v-if="items.length">
      <template v-if="$slots.item || $scopedSlots.item">
        <slot
          name="item"
          v-for="item in items"
          :item="item"
        ></slot>
      </template>
      <template v-else>
        <ui-item
          v-for="item in items"
          :key="item.key || item.text"
          v-bind="item"
          @click="$emit('click-item', item)"
          :icon-size="iconSize"
          :style="item.style"
        >
          <template #default>
            <slot
              name="item-text"
              :item="item"
            ></slot>
          </template>
          <template #icon>
            <slot
              name="item-icon"
              :item="item"
            ></slot>
          </template>
          <template #secondary>
            <slot
              name="item-secondary"
              :item="item"
            ></slot>
          </template>
          <template #tertiary>
            <slot
              name="item-tertiary"
              :item="item"
            ></slot>
          </template>
        </ui-item>
      </template>
    </template>
  </div>
</template>

<script>
import { UiItem } from "@/modules/ui/components";

export default {
  name: "ui-list",
  components: { UiItem },

  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false
    },

    iconSize: {
      type: [Number, String],
      required: false,
      default: "24"
    }
  }
};
</script>

<style lang="scss">
.ui-list {
  // max-width: 800px;

  .ui-item {
    display: flex;
  }

  &.--selectable {
    .ui-item {
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }

  &.--sublist {
    border-left: 1px solid rgba(0, 0, 0, 0.07);
    margin-left: 26px;
    margin-bottom: 26px;

    padding-left: 6px;
  }


  &.--grid {
    display: flex;
    flex-wrap: wrap;

    & > * {
      position: relative;
      flex: 1;

      min-width: 280px;
      padding-top: 120px;
      background-color: #fff;
      border-radius: var(--ui-radius);
      margin: var(--ui-breathe);

      .text-primary {
        white-space: nowrap;
      }


      /// !!! background from --ui-item-background  https://stackoverflow.com/questions/6457406/can-i-apply-multiple-background-colors-with-css3
      background-repeat: no-repeat;

      background-image:
      /* 1) Color(using gradient) */ linear-gradient(to right, #fff, #fff),
      /* 2) An image              */ var(--ui-item-background);

      background-position:
      /* 1) Color position        */ 0 100%,
      /* 2) Image position        */ 0 0;

      background-size:
      /* 1) Color size            */ 100% 64px,
      /* 2) Image size            */ cover;
    }
  }
}
</style>